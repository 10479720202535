import React, { useState, useEffect } from "react";
import Markdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from "lodash";
import { TypeWriterProps } from "../Services/CommonServices/propTypes";
import { conversationList } from "../Services/Redux/Reducers/chatSlice";

const Typewriter = (props) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const loading = useSelector((state) => state.common.isLoader);
  const conList = useSelector((state) => state.chat.conversationList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.effects === true && loading) {
      if (props.text) {
        if (currentIndex < props.text.length) {
          const timeout = setTimeout(() => {
            const displayedText = currentText;
            setCurrentText(prevText => prevText + props.text[currentIndex]);
            setCurrentIndex(prevIndex => prevIndex + 1);
            if (props.isAnswerComplete && props.text.length - 1 <= displayedText.length) {
              props.setLoading(true)
            }
          }, props.delay);

          return () => clearTimeout(timeout);
        } else {
          const displayedText = currentText;
          if (props.updateNextText) {
            props.updateNextText(true);
          }
          if (props.isAnswerComplete && props.text.length - 1 <= displayedText.length) {
            props.setLoading(true)
          }
        }
      } else {
        props.setLoading(true)
      }
    } else {
      props.setLoading(true)
    }
  }, [currentIndex, props.delay, props.text, props.isAnswerComplete, loading]);

  useEffect(() => {
    if (localStorage.getItem("stoppedResponseId") === props.convoId) {
      let convoList = cloneDeep(conList);
      const conIndex = convoList.findIndex(
        (element) => element.uid === props.convoId
      );
      if (props.type === "product") {
        convoList[conIndex]["response"]["products"][props.productIndex][
          props.propertyKey
        ] = currentText;
        convoList[conIndex]["response"]["products"][props.productIndex].responseStopped = true;
        if (props.propertyKey === 'type') {
          convoList[conIndex]["response"]["products"][props.productIndex]['name'] = '';
        }
        if (props.propertyKey === 'name') {
          convoList[conIndex]["response"]["products"][props.productIndex]['description'] = '';
        }
        const productIndex = convoList[conIndex]["response"][
          "products"
        ].findIndex((element, i) => i === props.productIndex);
        convoList[conIndex]["response"]["products"] = convoList[conIndex][
          "response"
        ]["products"].filter((product, index) => {
          return index <= productIndex;
        });
        convoList[conIndex]["response"]["responseConclusion"] = "";
        convoList[conIndex]["response"]["products"] = [...convoList[conIndex]["response"]["products"]];
        convoList[conIndex]["response"].responseStopped = true;
      } else if (props.type === "productDetail") {
        convoList[conIndex]["response"]["products"][props.productIndex][
          props.propertyKey
        ] = currentText;
        convoList[conIndex]["response"]["products"][
          props.productIndex
        ].responseStopped = true;
        if (props.propertyKey === 'name') {
          convoList[conIndex]["response"]["products"][props.productIndex]['description'] = '';
        }
        convoList[conIndex]["response"]["responseConclusion"] = "";
      } else {
        convoList[conIndex]["response"][props.type] = currentText;
        convoList[conIndex]["response"].responseStopped = true;
      }
      convoList[conIndex]["response"].suggestedPrompts = [];
      if (
        props.type === "responseText" &&
        convoList[conIndex]["response"].products &&
        convoList[conIndex]["response"].products.length > 0
      ) {
        convoList[conIndex]["response"]["products"] = [];
      }
      if (
        props.type === "responseText" &&
        convoList[conIndex]["response"].branding &&
        convoList[conIndex]["response"].branding.length > 0
      ) {
        convoList[conIndex]["response"]["branding"] = [];
      }
      dispatch(conversationList([...convoList]));
    }
  }, [localStorage.getItem("stoppedResponseId"), currentText]);

  return (
    <div className="typewriter">
      {
        props.effects === false ? (
          <Markdown>{props.text}</Markdown>
        ) : (loading && !currentText && !props.hideLoader) ? (<div style={{ margin: "10px 20px" }} className="cat-loader" />) : (
          <Markdown>{currentText}</Markdown>
        )
      }
    </div>
  );
}

Typewriter.propTypes = TypeWriterProps;

Typewriter.defaultProps = {
  delay: 10,
  effects: true,
  onComplete: () => {},
  isAnswerComplete: false,
  isStreaming: false,
  isStopResponseTriggered: false,
  setIsStopResponseTriggered: () => {}
};

export default Typewriter;