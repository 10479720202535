/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useRef } from "react";
import { AbortControllerProviderProps } from '../Services/CommonServices/propTypes';

const AbortControllerContext = createContext();

export const useAbortController = () => {
    return useContext(AbortControllerContext);
}

export const AbortControllerProvider = ({ children, ...props }) => {
    const controllerRef = useRef(null);

    const createController = () => {
        controllerRef.current = new AbortController();
        return controllerRef.current
    }

    const abortController = () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
            controllerRef.current = null
        }
    }

    return (
        <AbortControllerContext.Provider value={{ createController, abortController }} >
            {children}
        </AbortControllerContext.Provider>
    )

}

AbortControllerProvider.propTypes = AbortControllerProviderProps;

export default AbortControllerProvider;