import React, { useState } from "react";
import { DislikeModuleProps } from "../Services/CommonServices/propTypes";

const DislikeModule = (props) => {
  const [selectedFeedbackBtn, setSelectedFeedbackBtn] = useState("");
  const [feedbackText, setFeedbackText] = useState("");

  const handleFeedbackBtn = (btnId) => {
    setSelectedFeedbackBtn(btnId);
  };

  const handleFeedbackText = (event) => {
    const additionalfeedback = event.target.value;
    setFeedbackText(additionalfeedback);
  };

  const submitFeedback = (feedBackData) => {
    props.updateRes(feedBackData.feedBack);
    props.handleClose();
    setFeedbackText("");
  };

  const updateAppFeedback = () => {
    let feedBackData = '';
    if (selectedFeedbackBtn || (feedbackText && feedbackText.trim() !== "")) {
      if (selectedFeedbackBtn && feedbackText) {
        feedBackData = selectedFeedbackBtn + " - " + feedbackText;
      } else if (feedbackText) {
        feedBackData = feedbackText;
      } else {
        feedBackData = selectedFeedbackBtn;
      }
      let responseStatus = {
        feedBack: feedBackData
      };
      feedBackData = responseStatus
      console.log("responseStatus", responseStatus);
    }
    submitFeedback(feedBackData);
  };

  const customerFeedback = [
    "Style / Recommendation Accuracy",
    "Performance & Speed",
    "Ease of Use",
    "Tone & Voice",
    "Offensive or Unsafe Language",
    "Other Issues",
  ];

  return (
    <div>
      {props.isDislikeModalOpen && (
        <div className="modal d-flex" id="myModal" role="dialog">
          <div className="modal-content feedback-content">
            <button
              name="closeButton"
              type="button"
              className="close_btn align-self-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={props.handleClose}
            >
              <img src="./static/assets/Close_icon.svg" />
            </button>
            <div className="modal-content_inner">
              <div className="modal-body">
                <div className="headline pb-3">Thank You. Tell Us More</div>
                <div className="feedback-subtext pb-2">
                  Please help us improve by providing more <br /> details about your
                  experience
                </div>
                <div className="modal-body_suggestedFeedback justify-content-center">
                  {customerFeedback.map((value, index) => (
                    <div key={index} className="inputContainer">
                      <input
                        className={`feedbackMsg ${selectedFeedbackBtn === value ? "selected" : ""}`}
                        type="button"
                        value={value}
                        onClick={() => handleFeedbackBtn(value)}
                      />
                    </div>
                  ))}
                  <div className="feedback-textarea">
                    <p className="char-count">{feedbackText.length}/ 250 </p>
                    <textarea
                      className="feedback_optional_box"
                      placeholder="Provide additional details (optional)"
                      value={feedbackText}
                      maxLength={250}
                      onChange={(e) => handleFeedbackText(e)}
                    />
                  </div>
                </div>

                <button
                  name="submitButton"
                  type="button"
                  id="btnStyle"
                  className="btnSize submit_btn activeBtnColor"
                  onClick={updateAppFeedback}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DislikeModule.propTypes = DislikeModuleProps;

export default DislikeModule;
