import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PromptsCarouselProps } from "../../Services/CommonServices/propTypes";

function NextArrow({ onClick }) {
  return (
    <button
      type="button"
      className="next-arrow d-none d-lg-block"
      onClick={onClick}
      aria-label="Next slide"
    >
      <img
        className="pt-1 next-icon"
        src="/static/assets/NextArrow.svg"
        alt=""
        role="presentation"
      />
    </button>
  );
}

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};

function PrevArrow({ onClick }) {
  return (
    <button
      type="button"
      className="prev-arrow d-none d-lg-block"
      onClick={onClick}
      aria-label="Previous slide"
    >
      <img
        className="pt-1 prev-icon"
        src="/static/assets/PrevArrow.svg"
        alt=""
        role="presentation"
      />
    </button>
  );
}

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};

function PromptsCarousel({ handleCaroselAnimate, isBackFromArchive }) {
  const baseSettings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    accessibility: true,
    focusOnSelect: true,
  };
  const [settings, setSettings] = useState(baseSettings);

  useEffect(() => {
    const updateSettings = () => {
      const width = window.innerWidth;
      if (width > 577 && width < 991) {
        setSettings({
          ...baseSettings,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        });
      } else if (width < 576) {
        setSettings({
          ...baseSettings,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          centerMode: true,
          centerPadding: "50px",
        });
      } else {
        setSettings(baseSettings);
      }
    };
    window.addEventListener("resize", updateSettings);
    updateSettings();
    return () => {
      window.removeEventListener("resize", updateSettings);
    };
  }, []);

  const cards = [
    {
      id: 1,
      title: "Welcome",
      content: "I'm here to help you while you shop.",
    },
    {
      id: 2,
      title: "Outfit For Any Occasion",
      content: "What should I wear for a winter wedding in New York City? ",
    },
    {
      id: 3,
      title: "Product Discovery ",
      content: "Do you have any large Polo shirts in white? ",
    },
    {
      id: 4,
      title: "Timeless Gifting",
      content: "What gift should I get my significant other?",
    },
    {
      id: 5,
      title: "Brand History",
      content: "What's the orgin of the Polo Bear?",
    },
  ];

  return (
    <div
      className={`carousel-container slider-container container-fluid px-0 col-12 col-md-10 col-xl-9 ${!isBackFromArchive && 'animateSearchwithCarousel'} `}
      role="region"
      aria-label="Prompt suggestions carousel"
    >
      <Slider {...settings}>
        {cards.map((card, i) => (
          <div
            key={`prompt-card-${card.id}`}
            style={{ width: "275px" }}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                if (i > 0) {
                  handleCaroselAnimate(card?.content);
                }
              }
            }}
            aria-label={`Suggestion card: ${card.title}`}
          >
            <div className="headerContainer">
              <span className="headerContainer_title me-1">{card?.title}</span>
              {i > 0 && (
                <button
                  type="button"
                  name="tryItButton"
                  className="headerContainer_tryBtn btn--outline"
                  onClick={() => handleCaroselAnimate(card?.content)}
                  aria-label={`Try suggestion: ${card.content}`}
                >
                  Try it
                </button>
              )}
            </div>
            <div className="cardContent">
              <p className="cardContent-item">{card?.content}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

PromptsCarousel.propTypes = PromptsCarouselProps;

export default PromptsCarousel;
