import axios from "axios";
import { CONFIG_URL } from "./envConstants";

// axios configuration with api url
const instance = axios.create({
  baseURL: `${CONFIG_URL.apiUrl}/`,
});

instance.interceptors.request.use(
  async (config) => {
    console.log("Request=>", config);
    return config;
  }, (error) => {
    // Do Something with error
    console.log("ERROR", error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // Do Something after request
    console.log("Response=>", response);
    const { status } = response;
    if (status === 200) {
      return response;
    }
  }, (error) => {
    const { message } = error;
    console.log("ERROR", message);
    return Promise.reject(error);
  }
);

export { instance };