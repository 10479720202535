import React from "react";

function Restricted() {
    return (
        <div className=" d-flex justify-content-center align-items-center pagenotfound">
            <h1>
                Session Expired / Something went Wrong
            </h1>
        </div>
    );
}

export default Restricted;